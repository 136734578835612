import { useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';

// styles
import styles from './moduleview.module.scss';

// types
import type { FormModuleProps } from './Module.types';

// components
import {
  BUTTON_COLORS,
  BUTTON_BACKGROUND,
  BUTTON_ALIGNMENT,
  BUTTON_TYPES,
  Button,
} from 'components/Button/Button';
import { Lightbox } from 'components/lightbox/lightbox';
import { Translation } from 'components/Translation/Translation';
import ModuleView from './ModuleView';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';
import { FormController } from '../../Form/form-controller';
import { Button as GDDSButton } from '@geberit/gdds';

// constants
import { THEME_NAMES } from 'themes';

// utils
import { generateSlug } from 'utils/slug';
import { useThemeName } from 'utils/hooks/use-theme';

export function FormModule({
  headline,
  icon,
  text,
  buttonText,
  form,
  tileClass,
  index,
  itemsSize = 0,
}: Readonly<FormModuleProps>) {
  const pathname = usePathname();
  const hash = pathname.split('#')?.[1];
  const [modalOpen, setModalOpen] = useState(false);
  const id = generateSlug(headline);
  const themeName = useThemeName() ?? THEME_NAMES.DEFAULT;

  useEffect(() => {
    // open the regarding modal if hash is given in the url
    if (hash === id) {
      setModalOpen(true);
    }
  }, [hash, id]);

  const openModal = () => {
    setModalOpen(true);
    const hash = `#${id}`;
    // needs to set directly on window, otherwise there is no hash in the url
    window.history.replaceState(null, '', hash);
  };

  const closeModal = () => {
    setModalOpen(false);
    const url = window.location.href.split('#')[0];
    window.history.pushState({ path: url }, '', url);
  };

  const renderButton = () => {
    if (themeName === THEME_NAMES.DEFAULT) {
      return (
        <Button
          color={BUTTON_COLORS.WHITE}
          background={BUTTON_BACKGROUND.PRIMARY}
          align={BUTTON_ALIGNMENT.CENTER}
          onClick={openModal}
          noTracking
        >
          <InlineEdit previewId="#st_buttonText">{buttonText}</InlineEdit>
        </Button>
      );
    } else {
      return (
        <GDDSButton
          stretched
          onClick={openModal}
          stylingType={itemsSize === 1 ? 'primary' : undefined}
        >
          {buttonText}
        </GDDSButton>
      );
    }
  };

  return (
    <ModuleView headline={headline} icon={icon} text={text} tileClass={tileClass} index={index}>
      {buttonText && <div className={styles.formButtonWrapper}>{renderButton()}</div>}
      {form && modalOpen && (
        <Lightbox onCloseRequest={closeModal}>
          <div className="c-lightbox__container ">
            <div className="c-lightbox__title">
              {headline && <InnerHtml as="h3" content={headline} />}
              <Button
                symbol="close"
                type={BUTTON_TYPES.PLAIN}
                onClick={closeModal}
                className="c-lightbox__close"
                noTracking
              >
                <Translation id="web20_lightbox_close" />
              </Button>
            </div>
            <div className={styles.leadForm}>
              <FormController isModal {...form} formType="leadmodule" />
            </div>
          </div>
        </Lightbox>
      )}
    </ModuleView>
  );
}
