import React, { useRef, useEffect } from 'react';

// types
import type { IframeModuleProps } from './Module.types';

// components
import { ToolEmbed } from 'components/ToolEmbed/ToolEmbed';
import ModuleView from './ModuleView';

// constants
import { THEME_NAMES } from 'themes';

// utils
import { useThemeName } from 'utils/hooks/use-theme';

function IframeModule({
  index,
  headline = '',
  icon = '',
  text = null,
  url = null,
  buttonText = null,
  width = null,
  height = null,
  tileClass = null,
  itemsSize = 0,
}: Readonly<IframeModuleProps>) {
  const margins = useRef({
    horizontal: 0,
    vertical: 0,
  });
  const themeName = useThemeName() ?? THEME_NAMES.DEFAULT;

  useEffect(() => {
    margins.current = {
      horizontal: (100 - (width / window.innerWidth) * 100) / 2,
      vertical: (100 - (height / window.innerHeight) * 100) / 2,
    };
  }, [height, width]);

  let buttonStyle: 'primary' | 'secondary' | undefined = undefined;
  if (themeName !== THEME_NAMES.DEFAULT) {
    buttonStyle = itemsSize === 1 ? 'primary' : 'secondary';
  }

  return (
    <ModuleView headline={headline} icon={icon} text={text} tileClass={tileClass} index={index}>
      {url && width && height && (
        <ToolEmbed
          src={url}
          label={buttonText}
          hasInedit
          lightBoxStyle={{
            maxWidth: `${width}px`,
            maxHeight: `${height}px`,
            height: `${height}px`,
            margin: `${margins.current.horizontal / 2}vh ${margins.current.vertical / 2}vw`,
          }}
          useGddsButton={themeName !== THEME_NAMES.DEFAULT}
          buttonStyle={buttonStyle}
        />
      )}
    </ModuleView>
  );
}

export default IframeModule;
