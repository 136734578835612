import React from 'react';

// styles
import styles from './moduleview.module.scss';

// types
import type { ModuleViewProps } from './Module.types';

// components
import { Title } from '../../Title/Title';
import { TitleFormats } from '../../Title/title.types';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { Icon } from 'components/Icon/Icon';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';

function ModuleView({
  headline = '',
  icon = '',
  text = null,
  tileClass = null,
  children = null,
  index,
}: Readonly<ModuleViewProps>) {
  return (
    <div className={`${styles.tile} small-12 ${tileClass} cell`} data-preview-id={`#${index}`}>
      <div className={styles.content}>
        <span className={styles.icon}>
          <Icon symbol={icon} />
        </span>
        {headline && (
          <Title
            title={headline}
            Format={TitleFormats.h3}
            titlePreviewId="#st_headline"
            formatClassName="h4"
          />
        )}
        {text && (
          <InlineEdit previewId="#st_text">
            <InnerHtml as="p" content={text} className={styles.paragraph} />
          </InlineEdit>
        )}
        {children}
      </div>
    </div>
  );
}

export default ModuleView;
