import React from 'react';
import { useRouter } from 'next/navigation';
import key from 'weak-key';

// styles
import styles from './moduleview.module.scss';

// types
import type { LinkModuleProps } from './Module.types';

// components
import ModuleView from './ModuleView';
import { InternalLink } from 'components/Link/InternalLink';
import { ExternalLink } from 'components/Link/ExternalLink';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';
import { Button } from '@geberit/gdds';

// constants
import { THEME_NAMES } from 'themes';

// utils
import { useTracking as useTracking2 } from 'utils/tracking/track';
import { useThemeName } from 'utils/hooks/use-theme';
import { handlePopup } from 'utils/openInPopup';

function LinkModule({
  index,
  headline = '',
  icon = '',
  text = null,
  url = null,
  buttonText = null,
  tileClass = null,
  window = null,
  popup = undefined,
  itemsSize = 0,
}: Readonly<LinkModuleProps>) {
  const { trackClick } = useTracking2();
  const themeName = useThemeName() ?? THEME_NAMES.DEFAULT;
  const router = useRouter();

  const urlTarget = url ?? '';
  const windowTarget = window ? window : '';
  const urlType = /^[a-zA-Z]+:\/\//.test(urlTarget) ? 'external' : 'internal';
  const Link = urlType === 'external' ? ExternalLink : InternalLink;
  const content = {
    target: urlTarget,
    window: windowTarget,
    popup,
    showArrow: false,
  };

  function clickHandler() {
    trackClick({
      click_intent: 'cta',
      click_element: `lead-module_${headline}`,
      click_text: buttonText ?? undefined,
      click_url: urlTarget,
    });
  }

  function buttonClickHandler() {
    if (urlType === 'internal' && windowTarget === '_self') {
      // internal link in same window
      router.push(urlTarget);
    } else if (windowTarget === '_popup') {
      const windowId = key(content);
      handlePopup(content, windowId);
    } else {
      // external link or internal link in new window (or external link in same window)
      globalThis.window.open(urlTarget, windowTarget);
    }

    clickHandler();
  }

  const renderButton = () => {
    if (themeName === THEME_NAMES.DEFAULT) {
      return (
        <Link
          href={url}
          content={content}
          tracking={clickHandler}
          className="button button--white button--background-primary button--center"
        >
          <InlineEdit previewId="#st_buttonText">{buttonText}</InlineEdit>
        </Link>
      );
    } else {
      return (
        <Button
          stretched
          onClick={buttonClickHandler}
          stylingType={itemsSize === 1 ? 'primary' : undefined}
        >
          <InlineEdit previewId="#st_buttonText">{buttonText}</InlineEdit>
        </Button>
      );
    }
  };

  return (
    <ModuleView headline={headline} icon={icon} text={text} tileClass={tileClass} index={index}>
      <div className={styles.formButtonWrapper}>{renderButton()}</div>
    </ModuleView>
  );
}

export default LinkModule;
